import { graphql } from "gatsby";
import React from "react";
import Layout from "components/Layout";
import NewsAndEventsTabBar from "../ducks/NewsAndEventsTabBar";
import { TAB_SLUGS } from "../consts/news-and-events";
import Article from "../widgets/Article";
import { usePaginatedContent } from "../utils/pagination";

export default function NewsPage({
  data: {
    allMarkdownRemark: { edges },
  },
}) {
  const content = edges.map(
    (
      {
        node: {
          frontmatter: {
            newsStartDate,
            newsEndDate,
            newsImage,
            newsSubtitle,
            title,
            slug,
          },
        },
      },
      index
    ) => (
      <Article
        key={`${title}-${index}`}
        article={[
          {
            startDate: newsStartDate,
            endDate: newsEndDate,
            subTitle: newsSubtitle,
            linkUrl: slug,
            image: newsImage,
            title,
          },
        ]}
      />
    )
  );

  const paginatedContent = usePaginatedContent({ content });

  return (
    <Layout>
      <NewsAndEventsTabBar slug={TAB_SLUGS.NEWS} />
      {paginatedContent}
    </Layout>
  );
}

export const query = graphql`
  query news {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/news/**" } }
      sort: { fields: frontmatter___newsStartDate, order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            uid
            title
            text
            slug
            newsStartDate(formatString: "MM/DD/YYYY")
            newsEndDate(formatString: "MM/DD/YYYY")
            newsSubtitle
            newsImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData
              }
            }
            sections {
              ...CommonSectionFieldsFragment
              ...ArticleFragment
            }
          }
        }
      }
    }
  }
`;
