import React from "react";
import TabListItem from "./TabListItem";
import { Container, ItemsWrapper, ItemUL } from "./styles";
import { TAB_SLUGS } from "../../consts/news-and-events";

export default function NewsAndEventsTabBar({ slug }) {
  return (
    <Container>
      <ItemsWrapper>
        <ItemUL>
          <TabListItem
            key={`News-Tab`}
            label="News"
            isActive={slug === TAB_SLUGS.NEWS}
            linkURL={`/${TAB_SLUGS.NEWS}`}
          />
          <TabListItem
            key={`Upcoming-Events-Tab`}
            label="Upcoming Events"
            isActive={slug === TAB_SLUGS.UPCOMING_EVENTS}
            linkURL={`/${TAB_SLUGS.UPCOMING_EVENTS}`}
          />
          <TabListItem
            label=""
            isActive={false}
            pressHandler={() => null}
            hidden={true}
          />
        </ItemUL>
      </ItemsWrapper>
    </Container>
  );
}
