import React from "react";
import { Container, RowContent, PageNavigatorItem } from "./styles";

const PaginatorItem = ({ value, handler, isCurrentPage }) => {
  return (
    <PageNavigatorItem onClick={handler} current_page={isCurrentPage}>
      {value}
    </PageNavigatorItem>
  );
};

const Paginator = ({
  totalItems,
  pageSize,
  currentPageIdx,
  hidden,
  changeHandler,
}) => {
  if (hidden) {
    return null;
  }

  const totalPages = Math.ceil(totalItems / pageSize);
  const lastPageIndex = totalPages - 1;
  const handleClick = (val) => changeHandler(val);

  return (
    <Container>
      <RowContent>
        {currentPageIdx !== 0 && (
          <PageNavigatorItem onClick={() => handleClick(0)}>
            {"<< first"}
          </PageNavigatorItem>
        )}
        {currentPageIdx !== 0 && (
          <PageNavigatorItem onClick={() => handleClick(currentPageIdx - 1)}>
            {"< previous"}
          </PageNavigatorItem>
        )}
        {[...Array(totalPages).keys()].map((item, idx) => (
          <PaginatorItem
            key={`page-${item}-${idx}`}
            value={idx + 1}
            handler={() => handleClick(idx)}
            isCurrentPage={idx === currentPageIdx}
          />
        ))}
        {currentPageIdx !== lastPageIndex && (
          <PageNavigatorItem onClick={() => handleClick(currentPageIdx + 1)}>
            {"next >"}
          </PageNavigatorItem>
        )}
        {currentPageIdx !== lastPageIndex && (
          <PageNavigatorItem onClick={() => handleClick(lastPageIndex)}>
            {"last >>"}
          </PageNavigatorItem>
        )}
      </RowContent>
    </Container>
  );
};

export default Paginator;
