import React, { useState, useEffect } from "react";
import Paginator from "../components/Paginator";
import { PAGE_SIZE } from "../consts/news-and-events";

/**
 * Paginated method to return the correct slice of content
 * @param {{
 *  data: Array<any>;
 *  currentPageIndex: number;
 *  pageSize: number;
 *  totalItems: number;
 *  callback: (args?:any) => void;
 * }}
 */
export function getPaginatedContent({
  data,
  currentPageIndex = 0,
  pageSize = 10,
  totalItems = 10,
  callback,
}) {
  const startIndex = currentPageIndex * pageSize;
  const endIndex =
    startIndex + pageSize > totalItems ? totalItems : startIndex + pageSize;
  callback([...data.slice(startIndex, endIndex)]);
}

/**
 * Hook to return paginated data with page navigation component
 * @param {{
 *  content: Array<any>;
 *  pageSize?: number;
 * }}
 * @returns {ReactNode}
 */
export const usePaginatedContent = ({ content, pageSize = PAGE_SIZE }) => {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [pageContent, setPageContent] = useState(null);

  useEffect(() => {
    getPaginatedContent({
      data: content,
      totalItems: content.length,
      callback: setPageContent,
      currentPageIndex,
      pageSize,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageIndex]);

  const paginationChangeHandler = (value) => setCurrentPageIndex(value);

  if (!content || content.length === 0) {
    return <p>No content found.</p>;
  }

  return (
    <>
      {pageContent},
      <Paginator
        totalItems={content.length}
        pageSize={pageSize}
        currentPageIdx={currentPageIndex}
        changeHandler={(value) => paginationChangeHandler(value)}
        hidden={content.length <= pageSize}
      />
    </>
  );
};
