import Link from "gatsby-link";
import styled from "styled-components";

export const ItemLI = styled.li`
  display: flex;
  flex: 1 1 auto;
  min-width: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 20px 5px;
  ${({ active }) =>
    active === "true"
      ? `border-top: 1px solid #aaa;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border-left: 1px solid #aaa;
    border-right: 1px solid #aaa;`
      : `border-bottom: 1px solid #aaa;`}

  :hover {
    text-decoration: underline;
    text-decoration-color: ${({ active }) =>
      active === "true" ? "#382e2b" : "#ff5f00"};
  }
`;

export const ItemLink = styled(Link)`
  color: ${({ active }) => (active === "true" ? "#382e2b;" : "#ff5f00;")};
  font-family: Gotham, Helvetica, Arial, sans-serif;
  text-decoration: none;
  text-align: center;
  font-size: 18px;
  font-weight: 325;
  letter-spacing: 1px;
`;
