import styled from "styled-components";

export const PageNavigatorItem = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 10px;
  color: #ff5f00;
  font-size: 16pt;
  white-space: nowrap;
  font-weight: 400;
  ${({ current_page }) =>
    current_page &&
    `color: black;
    font-weight: bold;`}
  :hover {
    text-decoration: ${({ current_page }) =>
      current_page ? "none" : "underline"};
    cursor: pointer;
  }
`;

export const RowContent = styled.span`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;
