import React from "react";
import { ItemLink, ItemLI } from "./styles";

const TabItem = ({ label, isActive, linkURL, hidden = false }) => (
  <ItemLI
    key={label}
    active={isActive.toString()}
    role="presentation"
    hidden={hidden}
  >
    {!hidden && (
      <ItemLink to={linkURL} active={isActive.toString()}>
        {label.toUpperCase()}
      </ItemLink>
    )}
  </ItemLI>
);

export default TabItem;
